import React, { memo } from "react"
import { useState } from "react"
import { Typography } from "antd"

import InfoModal from "../components/InfoModal"
import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"

import DistributionPdf from '../files/Visiomate_Distribution_Utility.pdf';

const { Title } = Typography

const CaseStudeiesTopSection = ({ careerData, headerData, data, path }) => {
  const queryData = useStaticQuery(graphql`
    query CaseStudyQuery {
      VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  let backgroundImageClass
  let headerContainerClass

  switch (path) {
    case "blogs":
      backgroundImageClass = "blog-background"
      headerContainerClass = ""
      break
    case "news":
    case "webinarevents":
    case "whitepaperebooks":
      backgroundImageClass = "background-image-01"
      headerContainerClass = ""
      break
    case "projects":
      backgroundImageClass = "background-image-02"
      headerContainerClass = "header-container-color"
      break
    default:
      backgroundImageClass = "background-image"
      headerContainerClass = ""
      break
  }

  return (
    <div className={`case-Study-with-Logo ${backgroundImageClass}`}>
      <div className="image-setting-for-all logo-div-for-top-section">
        <Link to="/" style={{ paddingLeft: "0%", marginTop: "65px" }}>
          <Img
            fluid={
              path === "news" ||
                path === "webinarevents" ||
                path === "whitepaperebooks" ||
                path === "blogs"
                ? queryData.VMLogoBlack.childImageSharp.fluid
                : queryData.VMLogoWhite.childImageSharp.fluid
            }
            alt=""
            className="logo"
          />
        </Link>
      </div>

      <div className="caseStudies-top-section">
        <div className={`headings-container header-container-color`}>
          <Title
            className={`${path === "news" ||
                path === "webinarevents" ||
                path === "whitepaperebooks" ||
                path === "blogs"
                ? "header-heading"
                : ""
              }`}
          >
            {path === "blogs" ? careerData.data[0].title : headerData.title}
          </Title>
          <p className={`${path === "webinarevents"&&"eventsDescription"}`}>{headerData.peragraph}</p>
          {path === "blogs" ? (
            <Link
              to={
                path === "blogs"
                  ? `/BlogsDetails?editing=true&blog_id=${careerData.data[0].blog_id}`
                  : `/BlogsDetails?editing=true`
              }
              className="headings-container"
            >
              <button className="register-button">Contribute Now</button>
            </Link>
          ) : (
            ""
          )}
        </div>
        {path === "blogs" ? (
          <div className={`headings-container ${headerContainerClass}`}>
            <Title
              className={`${path === "news" ||
                  path === "webinarevents" ||
                  path === "whitepaperebooks" ||
                  path === "blogs"
                  ? "header-heading"
                  : ""
                }`}
            >
              {careerData.data[1].title}
            </Title>
            <p>{headerData.peragraph}</p>
            <Link
              to={`/BlogsDetails?editing=true&blog_id=${careerData.data[1].blog_id}`}
            >
              <button className="register-button">Contribute Now</button>
            </Link>
          </div>
        ) : (
          <div className="header-img">
            <Img fluid={data.newsHeaderImg.childImageSharp.fluid} />
          </div>
        )}
        {path === "news" ? (
          ""
        ) : path === 'webinarevents' ? (
          <Link to={headerData.state === 'Announced' && `/WebinarEvents/Register?id=${headerData.id}`}>
           {headerData.state==="Announced"?<button className="register-button">Register Now</button>:<span className="status-data">Status:{headerData.state}</span> }
          </Link>
        ) : path === "whitepaperebooks" ? (
          <a
            href={DistributionPdf}
            download="Visiomate_Distribution_Utility.Pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="register-button" >Download Now</button>
          </a>
        ) : path === "blogs" ? (
          ""
        ) : (
          <button onClick={showModal}>Request for Consultancy</button>
        )}
        <InfoModal
          companyIsEnabled
          url="http://46.101.195.121:8066/email/ContactUs"
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  )
}

export default memo(CaseStudeiesTopSection)
