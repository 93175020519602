import React from "react"
import Img from "gatsby-image"
const caseStudyDescription = ({ caseStudyData, data }) => {
    return (
        <div className="case-study-info">
            <div className="case-study-info-container">
                <div className="margin-top-class">
                    <p className="bold-text">{caseStudyData.ITitle}</p>
                    <p>{caseStudyData.Idescription}</p>
                </div>
                <div className="loop-grid">
                    <div>
                        {caseStudyData.data.map((d, index) => {
                            return (
                                <div>
                                    {d.Title === "keyReqProblem" ? (
                                        <div className=" margin-top-class">
                                            <p className="bold-text">Key Requirements / Problems</p>
                                            <ul className="ul-conatiner">
                                                <li>{d.Point1}</li>
                                                {d.Point2 !== "" ? <li>{d.Point2}</li> : ""}
                                                {d.Point3 !== "" ? <li>{d.Point3}</li> : ""}
                                            </ul>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    {d.Title === "Solution" ? (
                                        <div className=" solution-conatiner">
                                            <div>
                                                <p className="bold-text">Our Solution/Services</p>
                                                <p className="custom-p">{d.Sdescription}</p>
                                            </div>
                                            <div>
                                                <p className="bold-text">Key Value Additions</p>
                                                <ul>
                                                    <li>{d.Point1}</li>
                                                    {d.Point2 !== "" ? <li>{d.Point2}</li> : ""}
                                                    {d.Point3 !== "" ? <li>{d.Point3}</li> : ""}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <Img fluid={data.secondImg.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <div />
        </div>
    )
}

export default caseStudyDescription
