import React, { memo, useState } from 'react'

import InfoModal from "./InfoModal"
import { Button } from "antd"
import arrow from "../images/arrow.png"

const CaseStudeiesSimplified = ({ TextData }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    let buttonHide
    let WebinarEvents
    if (typeof window !== "undefined") {
        buttonHide = window.location.pathname.includes("/caseStudies/projects/")
        WebinarEvents = window.location.pathname.includes("/WebinarEvents")
    }
    return (
        <div>
            <div
                className="CaseStudeiesSimplified"
                style={{ backgroundColor: "#FFFFFF" }}
            >
                {WebinarEvents ? " " : <h1>{TextData.title}</h1>}
                <div
                    className={
                        buttonHide
                            ? "div-container-ORR"
                            : WebinarEvents
                                ? "div-container-webinar "
                                : "div-container"
                    }
                >
                    {TextData.Data.map((d, index) => {
                        return (
                            <div className={`div${index + 1}`} key={index}>
                                {!buttonHide && !WebinarEvents ? <h2>0{index + 1}</h2> : ""}
                                <p className="underlined-paragraph">
                                    <strong>{d.peragraph}</strong>
                                </p>
                                <ul>
                                    {d.list.map((item, itemIndex) => (
                                        <li key={itemIndex}>{item}</li>
                                    ))}
                                </ul>

                                {index === 0 && !buttonHide && !WebinarEvents && (
                                    <Button className="button-our" onClick={showModal}>
                                        Let’s Discuss
                                        <img
                                            src={arrow}
                                            width="20px"
                                            style={{ marginLeft: "4px" }}
                                            alt='connect arrow'
                                        />
                                    </Button>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default memo(CaseStudeiesSimplified)
